import * as Keycloak from 'keycloak-js'

const keycloakConfig = {
  url: 'https://login.jingraphics.com:8443/',  // Keycloak 서버 URL
  realm: 'TrionSvc',                 // Keycloak Realm 이름
  clientId: 'trionsvc-oclient'         // Keycloak 클라이언트 ID
}

const keycloak = new Keycloak(keycloakConfig)

const initKeycloak = () => {
  
  //alert(`initKeycloak in ${window.location.origin}`)

  return keycloak.init({
    onLoad: 'login-required',
    // redirectUri: 'https://trion-dev.jingraphics.com/auth',
    redirectUri: 'http://localhost:9090/',
    silentCheckSsoRedirectUri: 'http://localhost:8080' + '/silent-check-sso.html',
    // pkceMethod: 'S256',
    // checkLoginIframe: false,
    // flow: 'standard',
    enableLogging: true,
  })
}

const login = () => {
  //alert(`initKeycloak login ${window.location.origin}`)
  return keycloak.login({
    // redirectUri: 'https://trion-dev.jingraphics.com/auth',    
    redirectUri: 'http://localhost:9090/',
  })
}

const getToken = () => {
  return keycloak.token
}

export { keycloak, initKeycloak, login, getToken }