import __C from '../primitives/_constant_'
import * as msal from '@azure/msal-browser'

const msalConfig = {
  auth: {
    clientId: __C.MSAZURE.MSAL_CLIENT_ID,
    authority: __C.MSAZURE.MSAL_AUTHORITY,
    redirectUri: __C.MSAZURE.MSAL_REDIRECTURI
  },
  cache: {
    cacheLocation: __C.MSAZURE.MSAL_CACHE_LOCATION,
    storeAuthStateInCookie: __C.MSAZURE.MSAL_STORE_AUTH_STATE_IN_COOKIE,
  }
}

const msalInstance = new msal.PublicClientApplication(msalConfig)

export { msalInstance }


// export default {
//   data: () => ({
//     msalInstance: null,
//     msalConfig: {
//       auth: {
//         clientId: __C.MSAZURE.MSAL_CLIENT_ID,
//         authority: __C.MSAZURE.MSAL_AUTHORITY,
//         redirectUri: __C.MSAZURE.MSAL_REDIRECTURI,
//       },
//       cache: {
//         cacheLocation: __C.MSAZURE.MSAL_CACHE_LOCATION,
//         storeAuthStateInCookie: __C.MSAZURE.MSAL_STORE_AUTH_STATE_IN_COOKIE,
//       }
//     }
//   }),
//   created() {
//     this.msalInstance = new msal.PublicClientApplication(this.msalConfig)
//   },
// }