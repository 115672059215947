<template>
  <v-content>
    <v-container fill-height>
      <v-layout align-center justify-center class="otp_register_layout">
        <div class="otp_register_box_wrapper">
          <div class="otp_register_box">
            <div class="otp_register_header">
              <img src="@/assets/img/bici/logo.png" width="40"/>
              <div class="otp_register_title">
                <small>Register OTP</small>
                <span>Please complete OTP registration</span>
              </div>
            </div>
            <!-- 신규 OTP 등록 처리 -->
            <div v-if="!otpRegistered" class="button_wrapper">
              <v-btn color="primary" @click="registerNewOtp">CREATE OTP</v-btn>
              <!-- Cancel Button -->
              <v-btn color="secondary" @click="cancelNewOtp">Cancel</v-btn>
            </div> 
            <div v-if="otpRegistered">
              <div class="qr_code_wrapper">
                <img :src="qrCodeUrl" alt="QR Code for OTP APP" />
              </div>

              <div>
                <!-- Secret Key 표시 링크 -->
                <span 
                  @click="toggleSecretKey" class="secret_toggle"
                >
                  {{ showSecretKey ? '(Hide secret key)' : '(Show secret key)' }}
                </span>
                <div v-if="showSecretKey" class="secret_key">
                  <span>Alternatively, you can type a secret key. <strong>{{ secretKey }}</strong></span>
                </div>
              </div>

              <div class="input_fields">
                <v-text-field 
                  v-model="otpCode" 
                  name="otpCode" 
                  label="OTP Code" 
                  type="text"
                  placeholder="Fill in code from your OTP APP"
                ></v-text-field>
              </div>
              
              <div class="button_wrapper">
                <v-btn color="primary" @click="registerOtp">Register OTP</v-btn>
                <!-- Cancel Button -->
                <v-btn color="secondary" @click="cancelOtp">Cancel OTP</v-btn>
              </div>

              <div v-bind:style="messageStyle" class="message">{{ message }}</div>
            </div>
          </div>
        </div>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>

import '@/assets/stylus/ui/component/_signin.styl'

import __C from '@/primitives/_constant_'
import { AdminService } from "@/services"
import { mapState } from 'vuex'
import QRCode from 'qrcode'

export default {
  name: 'otp-reg',
  props: {
    userName: {type: String, required: true},
  },
  data() {
    return {
      showQrCode: false,    // QR 코드 초기 상태는 숨김
      showSecretKey: false, // Secret Key 초기 상태는 숨김
      otpCode: '',
      secretKey: '',
      qrCodeUrl: '',
      otpRequest: {},
      message: '',
      messageStyle: {
        visibility: 'hidden',
      },
      otpRegistered: false,
      adminService: null,
    }
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
  },
  created() {
    this.adminService = new AdminService()
  },
  methods: {
    // 기존 OTP가 있는지 확인
    checkIfOtpRegistered() {
      this.adminService.chkOtpSecret(this.userName,res => {
        if (res.secret) {
          this.otpRegistered = true
          this.message = "OTP has already been registered."
        } else {
          this.otpRegistered = false
          this.registerNewOtp()
        }
      })
    },

    // 새로운 OTP 등록 요청
    registerNewOtp() {
      this.otpRequest = {userName: this.userName}
      this.adminService.regOtpSecret(this.otpRequest,res => {
        // QR 코드 URL과 비밀 키를 화면에 표시
        this.otpRegistered = true
        QRCode.toDataURL(res.qrCodeUrl, (err, url) => {
          if (!err) {
            this.qrCodeUrl = url // QR 코드 이미지 URL 설정
          } else {
            console.error('QR code generation error', err)
          }
        })
        //this.qrCodeUrl = res.qrCodeUrl
        this.secretKey = res.secretKey
      })
    },

    // OTP 등록 요청
    registerOtp() {
      const payload = {
        otpCode: this.otpCode,
        userName: this.userName,
      }
      this.adminService.getOtpVerify(payload,res => {
        if (res.success) {
          this.message = res.success
          this.otpRegistered = true
          this.messageStyle.visibility = 'visible'
          this.$emit('otpRegisterSuccess') // 부모에게 OTP 등록 성공 이벤트 전달
        } else {
          this.message = res
          this.messageStyle.visibility = 'visible'
        }
      })
    },
    toggleQrCode() {
      this.showQrCode = !this.showQrCode; // QR 코드 표시 상태 토글
    },
    toggleSecretKey() {
      this.showSecretKey = !this.showSecretKey; // Secret Key 표시 상태 토글
    },
    // OTP 등록전 취소
    cancelNewOtp() {
      // 부모에게 취소 이벤트 전달
      this.$emit('otpRegisterNewCancel')
    },
    // OTP 생성후 취소
    cancelOtp() {
      // OTPSceret 생성 삭제후 취소
      if (this.otpRegistered &&  this.userName ) {
        this.adminService.delOtpSecret(this.userName,res => {
          console.log(res)
          if (res.error=='success') { // success
            this.message = res.message
            this.otpRegistered = false
            this.messageStyle.visibility = 'hidden'
            this.$emit('otpRegisterCancel') // 부모에게 OTP 등록 성공 이벤트 전달
          } else { //error
            this.message = res.message
            this.messageStyle.visibility = 'visible'
          }
        })

      }
    },
  }
}
</script>
